header {
  align-items: center;
  display: flex;
  margin: 2em;
}

.app-logo {
  font-size: 200%;
  flex: 1;
}

.app-user {
  flex: 1;
  display: inline-flex;
  justify-content: right;
}

.app-user > div {
  display: flex;
  padding: 5px;
}

.center {
  margin: auto;
  text-align: center;
}

.cover-image {
  height: 100%;
  width: 100%;
}

/* https://codepen.io/RedJokingInn/pen/eGQzYZ */

ul#menu {
  display: none;
}
ul#menu li a {
  width: 100%;
  display: block;
  background-color: #000;
  color: #fff;
  font-size: 1em;
  padding: 1.5em 1.6em;
  transition: background 0.2s;
  text-decoration: none;
}
ul#menu a:hover {
  background-color: #303030;
}

.rot45deg {
  transform: rotate(45deg);
  top: 10px !important;
}

.rot-45deg {
  transform: rotate(-45deg);
}

ul.hamburger {
  display: block;
}
ul.hamburger li:nth-child(1) {
  top: 0;
}
ul.hamburger li:nth-child(2) {
  top: 5px;
}
ul.hamburger li:nth-child(3) {
  top: 10px;
}

.hamburger-container {
  width: 38px;
  margin: 1.2em 1.6em;
  height: 30px;
  cursor: pointer;
  float: right;
}

@media screen and (min-width: 1000px) {
  .hamburger-container {
    display: none;
  }

  ul#menu {
    display: none;
  }
}
